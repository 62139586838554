// Shadows
$enable-shadows: true;

// Spacer
$spacer: 16px !default;

// Grid columns
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 12px !default;

// Vertical base space
$v-gap: 8px !default;

// Responsive gutter widths
$grid-gutter-widths: (
  sm: 12px,
  md: 20px,
  lg: 20px,
  xl: 28px,
) !default;

// Text
$font-family-serif: Lora, Georgia, serif !default;
$font-family-sans-serif: 'Titillium Web', Geneva, Tahoma, sans-serif !default;
$font-family-monospace: 'Roboto Mono', monospace !default;
$font-family-base: $font-family-sans-serif;
$text-color: $gray-700;
$text-muted: $gray-tertiary;
$letter-spacing-base: 0;
$line-height-base: 1.5;
$font-weight-base: 300;

// Paragraphs
$headings-line-height: 1.2 !default;
$paragraph-margin-bottom: 1rem !default;
$small-font-size: 0.777rem !default;

// Links
$link-decoration: underline;

// Focus
$focus-outline-color: $orange;

// Navbar
$navbar-brand-font-size: 1rem !default;
$navbar-toggler-border-radius: 0 !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTkuMnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxMiAxMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSIxMDI0dXAiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSItLWhvbWUtLS1wYXJhbGxheC0tLW1vYmlsZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI0LjAwMDAwMCwgLTIwLjAwMDAwMCkiIGZpbGw9IiNGRkZGRkYiPgogICAgICAgICAgICA8ZyBpZD0iLW5ldHdvcmstc2xpbS1oZWFkZXIiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTI0LDI0IEwzNiwyNCBMMzYsMjYgTDI0LDI2IEwyNCwyNCBaIE0yNCwyMCBMMzIsMjAgTDMyLDIyIEwyNCwyMiBMMjQsMjAgWiBNMjQsMjggTDMyLDI4IEwzMiwzMCBMMjQsMzAgTDI0LDI4IFoiIGlkPSJpY29uLXNtYWxsLWJ1cmdlciI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=") !default;
$navbar-dark-toggler-border-color: transparent;

// Buttons
$btn-border-width: 0;
$btn-text-transform: uppercase;
$btn-letter-spacing: normal;
$btn-font-weight: 600 !default;
$btn-font-size: 16px !default;
$btn-font-size-sm: 14px !default;
$btn-font-size-lg: 18px !default;

// Input
$input-btn-line-height: 1.5 !default;
$input-btn-line-height-sm: 1.45 !default;
$input-btn-line-height-lg: 1.6 !default;
$input-disabled-bg: $gray-disabled;
$input-spacing-x: 0.5rem;
$input-spacing-y: 0.375rem;
$input-border-width: 0;
$input-border-radius: 0;
$input-group-addon-bg: $white;
$input-height: 2.5rem;
$form-group-margin-bottom: 3rem;
$form-group-margin-top: 0;
$avatar-base-size: 8px;

// progress
$progress-height: 16px;
$progress-border-radius: 0;

// z-index
$zindex-zoom: 1080;
$zindex-growl: 1090;
$zindex-cookiebar: 1100;
$zoom-overlay-bg: $white;

// Border Radius - using ixels instead or rems as it has to be consistent across devices
$border-radius: 4px !default;
$border-radius-sm: 2px !default;
$border-radius-lg: 8px !default;

// Link and button colors
$btn-primary-bg: $primary;
$btn-primary-border: $primary;
$link-color: $primary;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// Code
$code-color: $dark;

$yiq-contrasted-threshold: 120 !default;

// Headings
$headings-font-weight: 700;
$headings-line-height: 1.2 !default;

$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;

$display1-size: 3.5rem;
$display2-size: 2.5rem;
$display3-size: 1.5rem;
$display4-size: 1.25rem;

$display1-weight: 700;
$display2-weight: 700;
$display3-weight: 700;
$display4-weight: 700;

// Breadcrumbs
// $breadcrumb-padding: 24px;
$breadcrumb-padding: 0.5em;
$breadcrumb-bg: transparent;
$breadcrumb-link-font-weight: 600;
$breadcrumb-link-color: $neutral-1-a7;
$breadcrumb-link-x-padding: 0.5em;
$breadcrumb-bg-dark: $neutral-1-a8;
$breadcrumb-border-radius: 0;
$breadcrumb-link-color-dark: $white;
$breadcrumb-icon-color-dark: $analogue-2-b1;

//Accordion
$card-cap-bg: transparent !default;
$card-spacer-y: 1rem !default;
$card-spacer-x: 2rem !default;
$card-border-radius: 0 !default;
$card-body-spacer: 1rem !default;

// Tables
$table-cell-padding: 1em !default;
$table-border-color: #d6dce3 !default;
$table-cell-text-align: left !default;
$table-accent-bg: #f6f7f9 !default;
$table-hover-bg: #e5f1fa !default;

// Tooltips
$tooltip-max-width: 32em !default;
$tooltip-opacity: 1 !default;
$tooltip-padding-y: 1rem !default;
$tooltip-padding-x: 1rem !default;

//Modals
$modal-backdrop-opacity: 0.8 !default;
$modal-max-width: 32rem !default;
$modal-dialog-margin-y-sm-up: 1.5rem !default;
$modal-footer-border-width: 0 !default;
$modal-header-border-width: 0 !default;
$modal-header-padding: 1.5rem !default;
$modal-inner-padding: 1.5rem !default;

// Carousel
$carousel-bg: #444e57 !default;
$carousel-padding: 3em !default;
$carousel-control-color: $secondary !default;
$carousel-control-width: auto !default;
$carousel-control-opacity: 1 !default;
$carousel-control-icon-width: 32px !default;

// Inputs
$input-border: $gray-200;
$input-label-color: $neutral-1-a8;
$input-color-placeholder: $gray-500;
$input-focus-border-color: $gray-secondary;

//List group
$list-group-item-padding-y: 1rem !default;
$list-group-action-color: $primary !default;
$list-group-disabled-cursor: default !default;

//Reset
$reset-border-width: 0 !default;
$reset-border-radius: 0 !default;
$reset-padding: 0 !default;

// Alert
$alert-padding-y: 1rem;
$alert-padding-x: 1rem;

// Blockquote
$blockquote-small-color: $gray-600;
$blockquote-small-color-dark: $gray-400;

// Skiplinks
$skiplink-padding-y: 0.5rem;
$skiplink-padding-x: 0.5rem;

// Dropdown
$dropdown-header-color: $dark;
$dropdown-link-hover-bg: #e6ecf2;
$dropdown-link-color: $dark;
$dropdown-link-hover-color: $dark;
$dropdown-item-padding-x: 24px;
$dropdown-item-padding-y: 12px;
$dropdown-border-width: 0;
$dropdown-border-color: transparent;
$dropdown-box-shadow: 0 20px 30px 5px rgba(0, 0, 0, 0.05);
$dropdown-box-shadow-vertical: 0 0 30px 5px rgba(0, 0, 0, 0.05);

// Dropdown custom
$dropdown-custom-button-padding: 0 4px;
$dropdown-custom-button-font-size: 1rem;
$dropdown-custom-button-color: $primary;
$dropdown-custom-button-background: transparent;
$dropdown-custom-button-caret-font-size: 0.55rem;
$dropdown-custom-button-caret-distance: $v-gap;
$dropdown-menu-animation-speed: 0.3s;
$dropdown-menu-vertical-shift: 16px;
$dropdown-menu-radius: 4px;
$dropdown-menu-notch-base-size: 6px;
$dropdown-menu-notch-position-x: 24px;

//Navigation
$navbar-bg-color: $primary;
$navigation-bg-color: $white;
$navigation-bg-color-desktop: $primary;

$navigation-h-padding: 24px;
$navigation-v-padding: 13px;
$navigation-sidebar-bg-color-mobile: rgba(0, 0, 0, 0.6);
$navigation-close-button-text-color: $primary;
$navigation-close-button-text-size: 0.75em;
$navigation-close-button-icon-size: 2em;
$navigation-links-margin-top: 102px;
$navigation-link-color: $primary;
$navigation-link-color-desk: $white;
$navigation-link-active-bar-size: 3px;
$navigation-link-active-bar-color: $primary;
$navigation-link-active-bar-color-desk: $white;
$navigation-toggle-button-icon-color: $white;
$navigation-toggle-button-icon-size: 1.625em;
$navigation-dropdown-icon-size: 0.8em;
$navigation-dropdown-icon-line-height: 2em;
$navigation-dropdown-icon-width: 1em;
$navigation-disabled-item-opacity: 0.5;
$navigation-hamburger-size: 24px;

// navigation dark theme (mobile)
$navigation-dark-bg-color: $primary;
$navigation-dark-text-color: $white;
$navigation-dark-bg-color: $primary-b1;
$navigation-dark-separator-color: $primary-c5;
//$navigation-dark-megamenu-separator-color:

// navigation light theme (desktop)
$navigation-light-bg-color: $white;
$navigation-light-text-color: $primary;
$navigation-light-megamenu-footer-bg-color: $primary-a7;
$navigation-light-separator-color: $neutral-1-a2;
$navigation-light-megamenu-separator-color: $primary-c5;

//inline
$inline-menu-line-width: 2px;

// Componente Base di Esempio
$componente-base-padding-x: 8px;
$componente-base-padding-y: 8px;

// Linklist
$link-list-font-size: 0.889em;
$link-list-font-size-l: 1em;
$link-list-line-height: 2.3em;
$link-list-line-height-l: 2.7em;
$link-list-heading-size: 1em;
$link-list-divider-height: 1px;
$link-list-divider-distance: 8px;
$link-list-h-pad: 24px;
$link-list-paragraph-size: 0.778em;
$link-list-left-icon-space: 8px;
$link-list-avatar-radius: 50px;
$link-list-avatar-margin: 8px;
$link-list-checkbox-icon-right: 35px;
$link-list-toggle-margin: 8px;

// Megamenu
$megamenu-padding-top-desktop: $v-gap * 4;
$megamenu-column-gap: $v-gap * 3;
$megamenu-link-small-line-width: 65px;
$megamenu-link-small-line-margin-bottom: $v-gap;
$megamenu-heading-line-height: 1.2em;
$megamenu-heading-font-weight: 600;
$megamenu-heading-bottom-margin: 0.5rem;
$megamenu-heading-text-size: 0.833em;
$megamenu-heading-margin-top: 5px;
$megamenu-heading-letter-spacing: 1px;
$megamenu-link-arrow-size: 0.8em;
$megamenu-footer-bg-color: $lightgrey-c2;
$megamenu-link-more-link-list-distance-mobile: $v-gap;
$megamenu-linklist-link-line-height: 1em;
$megamenu-linklist-link-v-padding: 0.5em;
$megamenu-liklist-vertical-margin-top: 25px;
$megamenu-liklist-vertical-margin-bottom: 16px;
$megamenu-linklist-vertical-link-padding: 36px;
$megamenu-vertical-desription-font-size: 0.889em;

// Linklist footer megamenu
$link-list-megamenu-footer-bg-color: $neutral-2;
$link-list-megamenu-footer-link-padding: 16px;
$link-list-footer-distance-bottom: 0.5rem;

// Pager
$pager-margin-bottom: 0.5rem;
$pager-item-size-mobile: 2.5rem; // 40px
$pager-item-size-tablet: 2.6666666666666665rem; // 48px
$pager-item-border-radius: 4px;
$pager-item-margin-right: 5px;
$pager-item-current-color: $primary;
$pager-item-current-border: 1px solid $primary;
$pager-font-size: 0.8888888888888888rem; // 16px
$pager-font-weight: 600;
$pager-font-color: $neutral-1-a7;
$pager-hover-color: $primary;
$pager-icon-color: $primary;
$pager-icon-size: 0.7rem;
$pager-disabled-color: $neutral-2-b2;
$pager-jump-to-width: 4.5rem;
$pager-jump-to-color: $neutral-2-b3;
$pager-page-changer-padding: 12px;
$pager-page-changer-border-bottom: 1px solid $gray-secondary;

// Sidebar
$sidebar-heading-font-weight: 600;
$sidebar-heading-bottom-margin: 0.8rem;
$sidebar-heading-text-size: 0.833em;
$sidebar-heading-margin-top: 5px;
$sidebar-heading-letter-spacing: 1px;
$sidebar-linklist-link-line-height: 1em;
$sidebar-linklist-link-v-padding: 0.8em;
$sidebar-link-small-line-width: 65px;
$sidebar-link-size: 1em;
$sidebar-dropdown-icon-size: 0.8em;
$sidebar-dropdown-line-selection-width: 2px;
$sidebar-dropdown-line-selection-color: $primary;
$sidebar-submenu-font-size: 0.889em;
$sidebar-submenu-link-v-padding: 0.7em;
$sidebar-border-color: $neutral-1-a2;

//sidebar dark theme
$sidebar-dark-bg-color: $neutral-1-a8;
$sidebar-dark-text-color: $white;
$sidebar-dark-separator-color: rgba(229, 229, 229, 0.3);

// Navscroll
$navscroll-bg-color: $white;
$navscroll-toggler-color: $primary;
$navscroll-button-text-weight: 600;
$navscroll-top-box-shadow: 0 0px 30px 5px rgba(0, 0, 0, 0.05);
$navscroll-bottom-box-shadow: 0 0px 30px 5px rgba(0, 0, 0, 0.05);
$navscroll-primary-font-weight: 600;
$navscroll-font-size: 1em;
$navscroll-selection-link-left: $sidebar-dropdown-line-selection-width solid $sidebar-dropdown-line-selection-color;
$navscroll-secondary-active-color: $neutral-1-a10;
$navscroll-links-padding: 0.8em;
$navscroll-bg-color-desk: $white;
$navscroll-line-color: $neutral-1-a2;
$navscroll-backbutton-padding: $v-gap * 2 $v-gap * 3;
$navscroll-backbutton-weight: 600;
$navscroll-backbutton-margin-top: $v-gap * 3;

//navscroll dark theme mobile
$navscroll-dark-bg-color: $neutral-1-a8;
$navscroll-dark-text-color: $white;
$navscroll-dark-separator-color: rgba(229, 229, 229, 0.3);

//dialog
$dialog-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
$modal-padding: $v-gap * 3;
$modal-margin: $v-gap * 6;
$modal-padding-close: $v-gap * 2;
$modal-heading-size: 0.8333333333333334rem;
$modal-heading-weight: 600;
$modal-heading-l-space: 0.5px;
$modal-heading-color: $neutral-1-a10;
$modal-p-size: 0.8888888888888888rem;
$modal-p-l-height: 1.3333333333333333rem;
$modal-icon-color: $primary-a6;
$modal-icon-distance: $v-gap * 2;
$modal-alert-p-distance: 32px + $v-gap * 2;
$modal-heading-border: 1px solid rgba(0, 0, 0, 0.05);
$modal-sticky-bg: $white;
$modal-body-height: 50vh;
$modal-popconfirm-p-size-mobile: 0.875rem;
$modal-popconfirm-p-size: 0.778rem;
$modal-popconfirm-max-width: 300px;

// ##### HEADER #####

// Header Slim
$header-general-padding: $v-gap * 3;
$header-slim-height: 48px;
$header-slim-bg-color: $primary-a7;
$header-slim-text-color: $white;
$header-slim-button-color: $primary-a9;
$header-slim-button-hover-color: $primary-a8;
$header-slim-brand-text-size: 0.778em;
$header-slim-icon-size: 18px;
$header-slim-padding-desk: 3px $v-gap * 3;
$header-slim-dropdown-distance: 14px;
$header-slim-dropdown-distance-mob: 9px;
$header-slim-links-padding: 5px;
$header-slim-brand-padding: 12px;
$header-slim-button-v-padding: 7.5px;
$header-slim-lang-v-padding: 12px;
$header-slim-h-padding: 18px;
$header-slim-v-padding-mob: 6.5px;
// Header Slim theme light
$header-slim-theme-light-bg-color: $white;
$header-slim-theme-light-text-color: $primary-a7;
$header-slim-theme-light-button-color: $primary;
$header-slim-theme-light-button-hover-color: $primary-a6;

// Header Center
// "!default"s here allows customising $header-center background and text colours as it's the most recognizable element.
$header-center-bg-color: $primary-a6 !default;
$header-center-text-color: $white !default;
$header-center-max-height: 120px;
$header-center-max-height-mob: 80px;
$header-center-pad: 47px;
$header-center-pad-mob: 16px;
$header-center-h2-size: 1.778em;
$header-center-h2-size-mob: 1.25em;
$header-center-h2-weight: 600;
$header-center-h3-size: 0.889em;
$header-center-icon-size: 82px;
$header-center-icon-size-mob: 48px;
$header-center-icon-margin: $v-gap * 2;
$header-center-icon-margin-mob: $v-gap;
$header-center-social-size: $v-gap * 3;
$header-center-social-distance: $v-gap * 2;
$header-center-search-size: $v-gap * 6;
$header-center-search-radius: $v-gap * 3;
$header-center-search-icon-size: $v-gap * 3;
$header-center-search-distance: $v-gap * 10;
$header-center-text-size: 0.889em;
$header-center-button-left: 10px;
$header-nav-button-distance: 22px;
$header-nav-icon-arrow-megamenu-distance: 14px;
$header-center-small-height: 104px;
$header-center-small-mob-height: 64px;
$header-center-small-h2-size: 1.333em;
$header-center-small-h3-size: 0.778em;
// Header Center theme light
$header-center-theme-light-bg-color: $white !default;
$header-center-theme-light-text-color: $primary-a6 !default;

// Anchors vertical offset:
$anchor-v-offset-lg: 72px;
$anchor-v-offset: $header-center-max-height-mob + 16px;

//cards
$card-padding: $v-gap * 3;
$card-h5-size: 1.125rem;
$card-h5-size-desk: 1rem;
$card-h5-line-height: 1.4444444444444444rem;
$card-h5-fw: 700;
$card-h5-color: $neutral-1-a10;
$card-p-size: 0.875rem;
$card-p-size-desk: 0.7777777777777778rem;
$card-p-l-h: 1.2rem;
$card-p-color: $neutral-1-a8;
$card-border-color: $neutral-1-a2;
$card-small-line-height: 1px;
$card-small-line-w: 128px;
$card-small-line-color: $neutral-1-a2;
$card-small-line-margin: $v-gap * 6;
$card-category-size: 0.7777777777777778rem;
$card-category-l-spacing: 0.9px;
$card-category-m-bottom: $v-gap * 2;
$card-big-head-size: 1.5555555555555556rem;
$card-big-head-l-h: 1.7777777777777777rem;
$card-signature-size: 0.8888888888888888rem;
$card-link-color: $primary-a6;
$card-link-icon-size: $v-gap * 2;
$card-cat-icon-block-margin: $v-gap * 2;
$card-cat-icon-size: $v-gap * 5;
$card-simple-link-margin: $v-gap * 4;
$card-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
$card-shadow-bg: $white;
$card-big-h5-size: 1.3333333333333333rem;
$card-big-h5-l-h: 1.5555555555555556rem;
$card-big-p-size: 0.8888888888888888rem;
$card-big-p-l-h: 1.3333333333333333rem;
$card-big-top-icon-size: $v-gap * 10;
$card-img-heading-size: 1.1111111111111112rem;
$card-img-heading-l-h: 1.5555555555555556rem;
$special-card-img-width: 174px;
// flag icon
$flag-icon-color: $primary-a6;
$flag-icon-h: $v-gap * 6;
$flag-icon-w: $v-gap * 4;
// tag
$tag-radius: 50px;

// select
$select-label-size: 0.7777777777777778rem;
$select-label-color: $neutral-1-a8;
$select-label-weight: 600;
$select-button-border: 1px solid $select-label-color;
$select-button-padding: $v-gap;
$select-button-text-size: 1rem;
$select-dd-shadow: $dialog-shadow;
$select-dd-text-size: 0.8888888888888888rem;
$select-dd-link-color: $primary;
$select-dd-link-color-active: $neutral-1-a10;
$select-dd-small-separator-width: 65px;
$select-dd-small-separator-bg: $neutral-1-a1;
$select-dd-icon-size: $v-gap * 3;
$select-dd-icon-flag-top: 10px;
$select-dd-pill-font-size: 0.7777777777777778rem;
$select-dd-pill-distance: $v-gap;
$select-dd-pill-font-w: 700;
$select-dd-header-size: 0.8333333333333334rem;
$select-dd-header-w: 600;
$select-dd-check-height: 30px;

//date picker
$dp-label-color: $neutral-1-a8;
$dp-border-bottom: 1px solid $dp-label-color;
$dp-shadow: $dialog-shadow;
$dp-arrow-size: $v-gap * 2;
$dp-month-size: 0.8333333333333334rem;
$dp-month-l-spacing: 0.1px;
$dp-grid-size: 13px;
$dp-current-size: $v-gap * 4;

//hero
$hero-bg-color: $primary-a6;
$hero-height-desk: 620px;
$hero-height-mob: 380px;
$hero-height-sm-desk: 400px;
$hero-height-sm-tab: 300px;
$hero-height-sm-mob: 230px;
$hero-text-padding: $v-gap * 3;
$hero-text-color: $white;
$hero-cat-size: 1rem;
$hero-heading-size: 2rem;
$hero-heding-size-desk: 2.6666666666666665rem;
$hero-p-size: 1rem;
$hero-cat-spacing: $card-category-l-spacing;
$hero-text-padding-desk: 120px;
$hero-dark-bg: rgba($neutral-1-a10, 0.54);
$hero-primary-bg: rgba($primary-a6, 0.85);
$hero-negative-margin: 80px;
$hero-negative-margin-desk: 80px;
$hero-negative-bottom-padding: 240px;
$hero-negative-bottom-padding-mob: 136px;

// Carousel
$crs-margin-bottom: $v-gap * 3;
$crs-mob-live-padding: $v-gap * 2;
$crs-dots-simension: $v-gap;
$crs-dots-color: $primary-a7;
$crs-dots-margin-left: 38px;
$crs-dots-margin-left-desk: $v-gap * 2;
$crs-heading-h-padding: $v-gap * 3;
$crs-heading-h-size: 1.75rem;
$crs-landcape-card-padding: $v-gap * 6;
$crs-landcape-bottom: 5px;

// Gridlist **************
$grid-item-df-proportion: 66.81222707423581%;
$grid-item-df-double-proportion: 33.4061135371179%;
$grid-item-text-size: 1rem;
$grid-item-text-size-mob: 0.8rem;
$grid-item-text-color: $neutral-1-a8;
$grid-item-text-overlay-bg: $neutral-1-a10;
$grid-list-default-gap: 2px;
$grid-list-text-gap: $v-gap;
$grid-item-sm-col: 50%;
$grid-item-lg-col: 33.333333%;
$grid-item-icon-mob-size: 24px;
$grid-item-icon-size-desk: 32px;

// list
$list-font-size: 1rem;
$list-text-padding: $v-gap * 2 0 $v-gap * 2 0;
$list-border-color: $neutral-1-a3;
$list-metadata-color: $neutral-1-a7;
$list-metadata-size: 12px;
$list-metadata-space: 0.5px;
$list-sub-size: 14px;
$list-sub-color: $neutral-1-a8;

//chips
$chips-background: #fcfdff;
$chips-background-hover: #8b98a6;
$chips-label-color: $gray-secondary;
$chips-label-color-disabled: $neutral-1-a7;

// transfer
$transfer-border: 1px solid $neutral-1-a3;
$transfer-descr-size: 12px;
$transfer-descr-color: $neutral-1-a8;
$transfer-header-line-color: $neutral-1-a3;
$transfer-header-line-w: 65px;
$transfer-height: 240px;

//Timeline
$timeline-width: 4px;
$timeline-padding: ($v-gap * 3)-$grid-gutter-width/2;
$timeline-border-color: linear-gradient(0deg, $primary 0%, #004080 100%);
$timeline-pin-size: $v-gap * 3;
$timeline-pin-circle-size: 48px;
$timeline-pin-circle-border: $v-gap solid $white;
$timeline-content-padding: 18px 0 18px 40px;
$timeline-content-padding-reverse: 18px 40px 18px 18px;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
//
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

// Hover states breackpoint
// Define minimum dimension for over states activation
//
$grid-breakpoints-hover: 1025px;
//
// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
//
$container-max-widths: (
  sm: 540px,
  md: 668px,
  lg: 960px,
  xl: 1184px,
) !default;
